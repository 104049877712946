export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    const button = document.querySelector("#menu-button");
    const navigation = document.querySelector("#navigation");
    const map = document.querySelector("#map");
    const buttonClose = document.querySelector("#close-button");
    const menu = document.querySelector("#menu");
    const body = document.querySelector("#body");

    button.addEventListener("click", () => {
      menu.classList.toggle("hidden");
      button.classList.toggle("hidden");
      buttonClose.classList.toggle("hidden");
      map.classList.toggle("hidden");
      body.classList.toggle("touch-none");
      // navigation.classList.toggle("absolute z-50");
    });

    buttonClose.addEventListener("click", () => {
      menu.classList.toggle("hidden");
      button.classList.toggle("hidden");
      buttonClose.classList.toggle("hidden");
      map.classList.toggle("hidden");
      body.classList.toggle("touch-none");
      // navigation.classList.toggle("absolute z-50");
    });
  });
};
