import hamburger from "./hamburger";
import aos from "./aos";
import lightboxslider from "./lightboxslider";
import languagepicker from "./languagepicker";
import navigation from "./navigation";
import videoModal from "./video-modal";
// import swiper from "./swiper";

hamburger();
aos();
// swiper();
lightboxslider();
languagepicker();
navigation();
videoModal();
