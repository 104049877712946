export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    // Get the current page URL
    var currentPage = window.location.pathname.split("/").pop().split(".")[0];
    // Find the corresponding navigation link and add the "active" class
    var navigationLinks = document.querySelectorAll('nav a[data-page="' + currentPage + '"]');
    navigationLinks.forEach(function (link) {
      link.classList.add("text-lightbrown");
    });
  });
};
