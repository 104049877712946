export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    var select = document.getElementById("language-picker-select");
    var selectmobile = document.getElementById("language-picker-select-mobile");

    // Set the selected option based on window.location.href
    var urlLanguage = window.location.pathname.split("/")[1]; // Extract language from URL
    if (urlLanguage === "fr") {
      select.value = "french";
      selectmobile.value = "french";
    } else if (urlLanguage === "en") {
      select.value = "english";
      selectmobile.value = "english";
    } else {
      select.value = "dutch";
      selectmobile.value = "dutch";
    }

    document.getElementById("language-picker-select").onchange = (event) => {
      var selectedLanguage = event.target.value;
      var currentPath = window.location.pathname;
      // Remove existing language code from the path
      const currentPath2 = currentPath.replace(/^\/(en|fr|nl)\//, "/");
      // Redirect to the appropriate language URL
      if (selectedLanguage === "french") {
        if (!currentPath.startsWith("/fr")) {
          window.location.href = "/fr" + currentPath2;
        }
      } else if (selectedLanguage === "dutch") {
        if (currentPath.startsWith("/fr") || currentPath.startsWith("/en")) {
          window.location.href = currentPath2;
        }
      } else if (selectedLanguage === "english") {
        if (!currentPath.startsWith("/en")) {
          window.location.href = "/en" + currentPath2;
        }
      }
    };

    document.getElementById("language-picker-select-mobile").onchange = (event) => {
      var selectedLanguage = event.target.value;
      var currentPath = window.location.pathname;
      // Remove existing language code from the path
      const currentPath2 = currentPath.replace(/^\/(en|fr|nl)\//, "/");
      // Redirect to the appropriate language URL
      if (selectedLanguage === "french") {
        if (!currentPath.startsWith("/fr")) {
          window.location.href = "/fr" + currentPath2;
        }
      } else if (selectedLanguage === "dutch") {
        if (currentPath.startsWith("/fr") || currentPath.startsWith("/en")) {
          window.location.href = currentPath2;
        }
      } else if (selectedLanguage === "english") {
        if (!currentPath.startsWith("/en")) {
          window.location.href = "/en" + currentPath2;
        }
      }
    };
  });
};
