import { Scrollbar, Navigation } from "swiper/modules";
import Swiper from "swiper";

export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    const body = document.getElementsByTagName("body")[0];
    window.globals = {};

    const lightboxImages = document.querySelectorAll("[lightbox-toggle] img");
    const initLightbox = lightboxImages.length > 0;
    console.log(initLightbox);
    if (initLightbox) {
      // Create skeleton for lightbox
      const lightbox = document.createElement("div");
      const lightboxContainer = document.createElement("div");
      const lightboxClose = document.createElement("div");
      const swiperContainer = document.createElement("div");
      const swiperWrapper = document.createElement("div");
      const swiperBtnNext = document.createElement("div");
      const swiperBtnPrev = document.createElement("div");
      const swiperPagination = document.createElement("div");

      // Add classes
      lightbox.classList.add("c-lightbox");
      lightboxContainer.classList.add("c-lightbox__container");
      lightboxClose.classList.add("c-lightbox__close");
      lightboxClose.setAttribute("tabindex", "0");
      lightboxClose.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>';
      swiperContainer.classList.add("swiper-container");
      swiperWrapper.classList.add("swiper-wrapper");
      swiperBtnNext.classList.add("swiper-button-next");
      swiperBtnNext.innerHTML =
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_292_6)"> <path d="M16.2829 20.0326L23.7829 12.5326C23.9226 12.3921 24.001 12.202 24.001 12.0038C24.001 11.8057 23.9226 11.6156 23.7829 11.4751L16.2829 3.9751L15.2254 5.0326L21.4429 11.2501L0.00038147 11.2501V12.7501L21.4429 12.7501L15.2254 18.9676L16.2829 20.0326Z" fill="white"/> </g> <defs> <clipPath id="clip0_292_6"> <rect width="24" height="24" fill="white" transform="matrix(0 1 -1 0 24 0)"/> </clipPath> </defs> </svg>';
      swiperBtnPrev.innerHTML =
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_292_6)"> <path d="M7.71712 20.0326L0.217118 12.5326C0.0774297 12.3921 -0.000976563 12.202 -0.000976563 12.0038C-0.000976563 11.8057 0.0774297 11.6156 0.217118 11.4751L7.71712 3.9751L8.77462 5.0326L2.55712 11.2501L23.9996 11.2501V12.7501L2.55712 12.7501L8.77462 18.9676L7.71712 20.0326Z" fill="white"/> </g> <defs> <clipPath id="clip0_292_6"> <rect width="24" height="24" fill="white" transform="matrix(0 1 1 0 0 0)"/> </clipPath> </defs> </svg>';
      swiperBtnPrev.setAttribute("tabindex", "0");
      swiperBtnPrev.classList.add("swiper-button-prev");
      swiperPagination.classList.add("swiper-pagination");
      swiperBtnNext.setAttribute("tabindex", "0");

      // Append created divs
      lightboxContainer.appendChild(lightboxClose);
      swiperContainer.appendChild(swiperWrapper);
      swiperContainer.appendChild(swiperBtnNext);
      swiperContainer.appendChild(swiperBtnPrev);
      swiperContainer.appendChild(swiperPagination);
      lightboxContainer.appendChild(swiperContainer);
      lightbox.appendChild(lightboxContainer);
      body.appendChild(lightbox);

      // Set variables to reference the lightbox
      globals.lightboxRef = document.querySelector(".c-lightbox");
      globals.swiperWrapperRef = document.querySelector(".c-lightbox .swiper-wrapper");
      // The rest of the code will go here
      lightboxImages.forEach((el, index) => {
        // Add click function to lightbox images
        el.addEventListener("click", imageClick, false);

        function imageClick() {
          // Clear swiper before trying to add to it
          globals.swiperWrapperRef.innerHTML = "";

          // Loop through images with lightbox data attr
          // Create html for lightbox carousel
          lightboxImages.forEach((img) => {
            // Create clone of current image in loop
            const image = img.cloneNode(true);
            // Create divs
            const slide = document.createElement("div");
            const imageContainer = document.createElement("div");
            // Add classes
            slide.classList.add("swiper-slide");
            imageContainer.classList.add("c-lightbox__image");
            // Append images to the slides, then slides to swiper wrapper
            imageContainer.appendChild(image);
            slide.appendChild(imageContainer);
            globals.swiperWrapperRef.appendChild(slide);
          });

          // Init Swiper
          const swiper = new Swiper(".c-lightbox .swiper-container", {
            modules: [Scrollbar, Navigation],
            initialSlide: index,
            loop: true,
            slidesPerView: 1,
            speed: 750,
            spaceBetween: 16,
            watchOverflow: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
              el: ".swiper-pagination",
              type: "fraction",
            },
            zoom: true,
            fadeEffect: {
              crossFade: false,
            },
            keyboard: {
              enabled: true,
              onlyInViewport: true,
            },
            mousewheel: {
              sensitivity: 1,
              forceToAxis: true,
              invert: true,
            },
          });

          // Add the class to open the lightbox
          // Add overflow hidden to the body to prevent scrolling
          globals.lightboxRef.classList.add("open");
          body.classList.add("overflowHidden");

          // Close lightbox on click
          document.addEventListener(
            "click",
            ({ target }) => {
              if (target.matches(".c-lightbox__close , .c-lightbox__close *")) {
                destroySwiper(swiper, 250);
                globals.lightboxRef.classList.remove("open");
                body.classList.remove("overflowHidden");
              }
            },
            false
          );

          // Close lightbox on escape key press
          document.addEventListener("keydown", ({ key }) => {
            if (key === "Escape") {
              destroySwiper(swiper, 250);
              globals.lightboxRef.classList.remove("open");
              body.classList.remove("overflowHidden");
            }
          });

          // Keyboard navigation with left and right arrows
          document.addEventListener("keydown", ({ key }) => {
            if (key === "ArrowLeft") {
              swiper.slidePrev();
            } else if (key === "ArrowRight") {
              swiper.slideNext();
            }
          });

          const destroySwiper = (swiper, timeout) => {
            setTimeout(() => {
              swiper.destroy();
            }, timeout);
          };
        }
      });
      document.getElementById("swiper-navigation-button-left").addEventListener("click", function (event) {
        console.log("click");
        event.stopPropagation(); // Stop click event propagation
      });
      document.getElementById("swiper-navigation-button-right").addEventListener("click", function (event) {
        console.log("click");
        event.stopPropagation(); // Stop click event propagation
      });
    }
  });
};
