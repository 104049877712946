export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    function openModal() {
      var modal = document.getElementById("myModal");
      var img = document.getElementById("myImg");
      var modalImg = document.getElementById("img01");
      modal.style.display = "block";
    }

    function closeModal() {
      var modal = document.getElementById("myModal");
      var iframe = modal.querySelector("iframe");

      var iframeSrc = iframe.src;
      iframe.src = "";
      iframe.src = iframeSrc;

      modal.style.display = "none";
    }

    // Assuming `myImg` is the ID of your image element
    var img = document.getElementById("myImg");
    if (img) {
      img.addEventListener("click", openModal);
    }

    // Similarly, add listeners for any other elements that should trigger closeModal
    // For example, if you have a close button:
    var closeButton = document.getElementById("close-modal");
    if (closeButton) {
      closeButton.addEventListener("click", closeModal);
    }
  });
};
